<template>

    <div>

        <div class="row wrapper border-bottom white-bg page-heading">
            <div class="col-sm-4">
                <h2>Auditoría de Tiempos</h2>
                <ol class="breadcrumb">
                    <li class="active">
                        <strong>{{ estacion.estacion }}</strong>
                    </li>
                    <li>
                        <a href="#">{{ estacion.formato }}</a>
                    </li>
                </ol>
            </div>
        </div>


        <div class="row">
            <div class="col-lg-12">
                <div class="ibox float-e-margins">
                    <div class="ibox-content p-md">
                        <ingreso-full 
                            :estacion_de_servicio_id="estacion.id"
                            :preguntas="estacion.preguntas"
                            :estacion="estacion"
                        ></ingreso-full>
                        <!-- <ingreso-self v-if="estacion.formato == 'SELF'" 
                            :estacion_de_servicio_id="estacion.id"
                            :preguntas="estacion.preguntas"
                        ></ingreso-self> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import IngresoFull from './IngresoFull.vue'
    import IngresoSelf from './IngresoSelf.vue'
    
    export default {
        components: {
            IngresoFull, IngresoSelf
        },
        data() {
            return {
                estacion: {},
            }
        },
        methods: {
			getPreguntas() {
                  this.$Progress.start();
                  axios.get('api/estaciones').then(({ data }) => (this.estacion = data.data));
                  this.$Progress.finish();
            },
		},
        mounted() {
            // console.log('Component mounted.')
			this.getPreguntas();
        }
    }
</script>
