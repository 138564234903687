<template>
    <div>
        <div class="form-group">
            <h4>
                Buenos días / tardes, estamos realizando una breve encuesta de sólo 2 preguntas, nos puede ayudar?
            </h4>
        </div>
         
        <form @submit.prevent="createEncuesta()">
            <div class="form-group">
                <label for="p1">
                    1. Basado en la experiencia que ha tenido en esta Estación de Servicio y considerando una escala de 0 a 10, en la que 0 significa "no recomendarías en absoluto" y 10 "definitivamente lo recomendarías", ¿Qué tanto recomendarías Petrobras a un familiar o amigo?
                </label>
                <select v-model="form.p1" class="form-control" id="p1" required>
                    <option value=0>0</option>
                    <option value=1>1</option>
                    <option value=2>2</option>
                    <option value=3>3</option>
                    <option value=4>4</option>
                    <option value=5>5</option>
                    <option value=6>6</option>
                    <option value=7>7</option>
                    <option value=8>8</option>
                    <option value=9>9</option>
                    <option value=10>10</option>
                </select>
            </div>
            
            <div v-if="form.p1 && (form.p1 >= 0 && form.p1 <= 6)" class="form-group">
                <label for="p1_1">
                    1.1 ¿Por qué no la recomendarías? 
                </label>
                <input v-model="form.p1_1" type="text" class="form-control" id="p1_1" aria-describedby="emailHelp" required>
            </div>

            <div v-if="form.p1 && (form.p1 >= 7 && form.p1 <= 8)" class="form-group">
                <label for="p1_2">
                    1.2 ¿Qué falta para que la recomiendes?
                </label>
                <input v-model="form.p1_2" type="text" class="form-control" id="p1_2" aria-describedby="emailHelp" required>
            </div>

            <div v-if="form.p1 && (form.p1 >= 9 && form.p1 <= 10)" class="form-group">
                <label for="p1_3">
                    1.3 ¿Cuáles son las razones de recomendación? 
                </label>
                <input v-model="form.p1_3" type="text" class="form-control" id="p1_3" aria-describedby="emailHelp" required>
            </div>



            <div  class="form-group">
                <label for="p2">
                    2. En relación al tiempo de espera para ser atendido, le pareció: {{ form.p2 }}
                </label>

                <div class="form-check">
                    <input v-model="form.p2" :disabled="!form.p1" class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" value="1" required>
                    <label class="form-check-label" for="flexRadioDefault1">
                        Mucho tiempo de espera
                    </label>
                </div>
                <div class="form-check">
                    <input v-model="form.p2" :disabled="!form.p1" class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" value="2" required>
                    <label class="form-check-label" for="flexRadioDefault2">
                        Tiempo adecuado de espera
                    </label>
                </div>
                <div class="form-check">
                    <input v-model="form.p2" :disabled="!form.p1" class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" value="3" required>
                    <label class="form-check-label" for="flexRadioDefault2">
                        Muy rápido, muy corta la espera
                    </label>
                </div>
                
            </div>

            

            <!-- <div  class="form-group">
                <label for="p2">
                    2. En relación al tiempo de espera para ser atendido, le pareció:
                </label>
                <select :disabled="!form.p1" v-model="form.p2" class="form-control" id="p2" required>
                    <option value=1>Mucho tiempo de espera</option>
                    <option value=2>Tiempo adecuado de espera</option>
                    <option value=3>Muy rápido, muy corta la espera</option>
                </select>
            </div> -->

            <div class="form-group">
                <label for="nombre">
                    Nombre 
                </label>
                <input v-model="form.nombre" :disabled="!form.p1" type="text" class="form-control" id="nombre" aria-describedby="emailHelp" required>
            </div>

            <div class="form-group">
                <label for="telefono">
                    Teléfono 
                </label>
                <input v-model="form.telefono" :disabled="!form.p1" type="text" class="form-control" id="telefono" aria-describedby="emailHelp" required>
            </div>

            <div class="form-group">
                <label for="comentario">
                    Comentario 
                </label>
                <input v-model="form.comentario" :disabled="!form.p1" type="text" class="form-control" id="comentario" aria-describedby="emailHelp">
            </div>

            <button :disabled="!boton_activo" type="submit" class="btn btn-success">Guardar</button>
        </form>

        <div class="form-group">
            <h4>
                ¡MUCHAS GRACIAS POR RESPONDER ESTA ENCUESTA!
            </h4>
        </div>

    </div>
</template>

<script>
    export default {
        props: ['evaluacion'],
        data() {
            return {
                boton_activo: true,
                form: new Form({
                    p1 : null,
                    p1_1: '',
                    p1_2: '',
                    p1_3: '',
                    p2: '',
                    nombre: '',
                    telefono: '',
                    comentario: ''
                })
            }
        },
        methods: {
            createEncuesta() {
                this.boton_activo = false;
                this.form.post('api/encuesta/' + this.evaluacion.id)
                    .then((response)=>{

                        Toast.fire({
                                icon: 'success',
                                title: response.data.message
                        });

                        this.$Progress.finish();
                        // this.loadUsers();
                        window.location.href = 'dashboard';
                    })
                    .catch(()=>{
                        Toast.fire({
                            icon: 'error',
                            title: 'Some error occured! Please try again'
                        });
                    })
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>