<template>
	<div class="row">
		<p>
			Auditorías Validas {{ auditoriasValidas }}
		</p>
		<div v-if="!muestraResumenProtocolo" class="col-lg-12">
			<div class="ibox float-e-margins">
				<div>
					<div v-for="pregunta in preguntas" :key="pregunta.id" class='row preg'>
						<input-tipo-vehiculo v-if="pregunta.tipo_pregunta == 'tipo_vehiculo'" 
							:pregunta="pregunta"
							:evaluacion.sync="evaluacion"
							:muestraP="muestraP"
							:estacion_de_servicio_id="estacion_de_servicio_id"
							:tipo_medicion="estacion.formato"
						></input-tipo-vehiculo>

						<input-click-time v-else-if="evaluacion && pregunta.tipo_pregunta == 'click'" 
							:pregunta="pregunta"
							:actualiza.sync="actualiza"
							:evaluacion_id="evaluacion.id"
							:muestraP="muestraP"
						></input-click-time>

						<input-click-time-fin v-else-if="evaluacion && pregunta.tipo_pregunta == 'click_fin'" 
							:pregunta="pregunta"
							:actualiza.sync="actualiza"
							:evaluacion_id="evaluacion.id"
							:muestraP="muestraP"
						></input-click-time-fin>
						
						<input-patente v-else-if="evaluacion && pregunta.tipo_pregunta == 'patente'" 
							:pregunta="pregunta"
							:actualiza.sync="actualiza"
							:evaluacion_id="evaluacion.id"
							:muestraP="muestraP"
						></input-patente>
						
						<input-cantidad v-else-if="evaluacion && pregunta.tipo_pregunta == 'cantidad'" 
							:pregunta="pregunta"
							:actualiza.sync="actualiza"
							:evaluacion_id="evaluacion.id"
							:muestraP="muestraP"
						></input-cantidad>

						<input-check v-else-if="evaluacion && pregunta.tipo_pregunta == 'check'" 
							:pregunta="pregunta"
							:actualiza.sync="actualiza"
							:evaluacion_id="evaluacion.id"
							:muestraP="muestraP"
						></input-check>

						<input-check-filtro v-else-if="p3 && evaluacion && pregunta.tipo_pregunta == 'check_filtro'" 
							:pregunta="pregunta"
							:actualiza.sync="actualiza"
							:evaluacion_id="evaluacion.id"
							:muestraP="muestraP"
						></input-check-filtro>

						<input-check-na v-else-if="evaluacion && pregunta.tipo_pregunta == 'check_na'" 
							:pregunta="pregunta"
							:actualiza.sync="actualiza"
							:evaluacion_id="evaluacion.id"
							:muestraP="muestraP"
						></input-check-na>

						<input-check-na-filtro v-else-if="evaluacion && pregunta.tipo_pregunta == 'check_na_filtro'" 
							:pregunta="pregunta"
							:actualiza.sync="actualiza"
							:evaluacion_id="evaluacion.id"
							:muestraP="muestraP"
						></input-check-na-filtro>

						<input-check-na-filtro-2 v-else-if="p4 && evaluacion && pregunta.tipo_pregunta == 'check_na_filtro2'" 
							:pregunta="pregunta"
							:actualiza.sync="actualiza"
							:evaluacion_id="evaluacion.id"
							:muestraP="muestraP"
						></input-check-na-filtro-2>



						



						<input-si-no v-else-if="evaluacion && pregunta.tipo_pregunta == 'si_no'" 
							:pregunta="pregunta"
							:actualiza.sync="actualiza"
							:evaluacion_id="evaluacion.id"
							:muestraP="muestraP"
						></input-si-no>

						<input-si-no-filtro v-else-if="p2_1 && evaluacion && pregunta.tipo_pregunta == 'si_no_filtro'" 
							:pregunta="pregunta"
							:actualiza.sync="actualiza"
							:evaluacion_id="evaluacion.id"
							:muestraP="muestraP"
						></input-si-no-filtro>

						<input-valor v-else-if="evaluacion && pregunta.tipo_pregunta == 'valor'" 
							:pregunta="pregunta"
							:actualiza.sync="actualiza"
							:evaluacion_id="evaluacion.id"
							:muestraP="muestraP"
						></input-valor>

						<input-forma-pago v-else-if="evaluacion && pregunta.tipo_pregunta == 'forma_pago'" 
							:pregunta="pregunta"
							:actualiza.sync="actualiza"
							:evaluacion_id="evaluacion.id"
							:muestraP="muestraP"
						></input-forma-pago>

						<!-- <div v-else>*****{{ pregunta.tipo_pregunta }}*****</div> -->
					</div>

					<div v-if="evaluacion" class="row preg">
						<div class="form-group  row"><label class="col-sm-2 col-form-label">Comentario</label>
							<div class="col-sm-10"><input v-model="comentario" type="text" class="form-control"></div>
						</div>
					</div>
					<div v-if="evaluacion" class="row preg">
						<a href="#" class="btn btn-primary">
							Faltan ({{ estadoObligatorio ? estadoObligatorio.faltan : '' }}) obligatorias
						</a>
						
						
						
						
						<!-- <button @click="obligatorioListado()" class="btn btn-primary">
							Revisión obligatorias
						</button> -->
						<!-- <button @click="protocoloListado()" class="btn btn-primary">
							Protocolo
						</button> -->




						<button @click="nuevaMedicion()" class="btn btn-primary">
							Finalizar Entrevista
						</button>
					</div>
				</div>
			</div>
		</div>
		<div v-else class="col-lg-12">

			<!-- estadoObligatorio ? estadoObligatorio.faltan -->

			<h3 v-if="resultadoFinal.obligatoria && !resultadoFinal.protocolo">
				<span class="text-danger">
					<strong>ATENCIÓN AUDITOR</strong>: En esta Pauta NO SE HA CUMPLIDO el protocolo de atención. Por favor informe inmediatamente a su supervisor y Jefe de Estudios
				</span>
			</h3>
			<h3 v-if="resultadoFinal.obligatoria && resultadoFinal.protocolo">
				La Pauta de auditoría ha sido validada y guardada correctamente
			</h3>

			<h3 v-if="!resultadoFinal.obligatoria">
				Faltaron preguntas obligatorias. ESTA PAUTA NO ES VALIDA
			</h3>

			<br/>

			<table v-if="resultadoFinal.obligatoria && !resultadoFinal.protocolo" class="table">
				<thead>
					<tr>
						<th>Pregunta</th>
						<th>Texto</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="muestraResumen in estadoProtocolo.listado" :key="muestraResumen.id">
						<td>{{ muestraResumen.pregunta_texto }}</td>
						<td>{{ muestraResumen.literal }}</td>
					</tr>
				
				</tbody>
			</table>

			

			<a href="/dashboard" class="btn btn-primary">
				Nueva medición
			</a>
		</div>
	</div>
   

</template>

<script>

	import InputPatente from './input/InputPatente.vue'
	import InputClickTime from './input/InputClickTime.vue'
	import InputClickTimeFin from './input/InputClickTimeFin.vue'
	import InputCantidad from './input/InputCantidad.vue'
	import InputCheck from './input/InputCheck.vue'
	import InputCheckFiltro from './input/InputCheckFiltro.vue'
	import InputCheckNa from './input/InputCheckNA.vue'
	import InputCheckNaFiltro from './input/InputCheckNAFiltro.vue'
	import InputCheckNaFiltro2 from './input/InputCheckNAFiltro2.vue'
	import InputSiNo from './input/InputSiNo.vue'
	import InputSiNoFiltro from './input/InputSiNoFiltro.vue'
	import InputValor from './input/InputValor.vue'
	import InputTipoVehiculo from './input/InputTipoVehiculo.vue'
	import InputFormaPago from './input/InputFormaPago.vue'


    export default {
		components: {
			InputPatente, InputClickTime, InputClickTimeFin, 
			InputCantidad, InputCheck, InputCheckFiltro, InputCheckNa, InputCheckNaFiltro, InputCheckNaFiltro2, 
			InputSiNo, InputSiNoFiltro, InputValor, InputTipoVehiculo, InputFormaPago
		},
		props: ['estacion_de_servicio_id', 'preguntas', 'estacion'],
		data() {
			return {
				comentario: '',
				evaluacion: null,
				actualiza: null,
				// preguntas: {},
				muestraP : true,
				
				respP2: null,
				respP2_1: null,
				respP3: null,
				respP4: null,

				p2_1: false,
				p3: false,
				p4: false,

				estadoObligatorio: {},
				estadoObligatoriolistado: {},
				estadoProtocolo: {},
				
				resultadoFinal: {},

				muestraResumenProtocolo: false,

				auditoriasValidas: null
			}
		},
		methods: {
			getPreguntaById_P2() {
				let url =  'api/evaluacion_detalle/' + this.evaluacion.id + '/p2';
				this.$Progress.start();
				axios.get(url).then(data => {
					this.respP2 = data.data.data;
					if (this.respP2.texto == 'cumple'){
						this.p2_1 = true;
						this.p4 = false;
						
						this.getPreguntaById_P2_1();

					}
					else if (this.respP2.texto == 'no cumple'){
						this.p2_1 = false;
						this.p3 = false;
						this.p4 = false;
					}
					else if (this.respP2.texto == 'no aplica'){
						this.p2_1 = false;
						this.p4 = true;
					}

					

				})
				.catch(e => {
					// Capturamos los errores
				})
				this.$Progress.finish();
            },
			getPreguntaById_P2_1() {
				let url =  'api/evaluacion_detalle/' + this.evaluacion.id + '/p2_1';
				this.$Progress.start();
				axios.get(url).then(data => {
					this.respP2_1 = data.data.data;
					if (this.respP2.texto == 'cumple' && this.respP2_1.texto == 'si'){
						this.p3 = false;
						this.p4 = true;
					}
					else if (this.respP2.texto == 'cumple' && this.respP2_1.texto == 'no'){
						this.p3 = true;
						this.p4 = false;
					}
				})
				.catch(e => {
					// Capturamos los errores
				})
				this.$Progress.finish();
            },
			getPreguntaById_P3() {
				let url =  'api/evaluacion_detalle/' + this.evaluacion.id + '/p3';
				this.$Progress.start();
				axios.get(url).then(({ data }) => (this.respP3 = data.data));
				this.$Progress.finish();
            },
			evaluacionesValidas() {
				let url =  'api/evaluaciones_validas';
				this.$Progress.start();
				axios.get(url).then(({ data }) => (this.auditoriasValidas = data.data));
				this.$Progress.finish();
            },
			getPreguntaById_P4() {
				let url =  'api/evaluacion_detalle/' + this.evaluacion.id + '/p4';
				this.$Progress.start();
				axios.get(url).then(({ data }) => (this.respP4 = data.data));
				this.$Progress.finish();
            },
			// getPreguntas() {
            //       this.$Progress.start();
            //       axios.get('api/preguntas/full').then(({ data }) => (this.preguntas = data.data));
            //       this.$Progress.finish();
            // },
			actualizaFiltro(){
				this.getPreguntaById_P2();
				
				// this.getPreguntaById_P3();
				// this.getPreguntaById_P4();
			},
			obligatorio(){
				let url =  'api/obligatorio/' + this.evaluacion.id;
				this.$Progress.start();
				axios.get(url).then(({ data }) => (this.estadoObligatorio = data.data));
				this.$Progress.finish();
			},
			obligatorioListado(){
				let url =  'api/obligatorio_listado/' + this.evaluacion.id;
				this.$Progress.start();
				axios.get(url).then(({ data }) => (this.estadoObligatorioListado = data.data));
				this.$Progress.finish();
			},
			protocoloListado(){
				let url =  'api/protocolo_listado/' + this.evaluacion.id;
				this.$Progress.start();
				// await axios.get(url).then(({ data }) => (this.estadoProtocolo = data.data));
				axios.get(url).then(response => {
					this.estadoProtocolo = response.data.data;
				}).then(response => {
					this.actualizaEstado();
				})
				.catch(e => {
					// Capturamos los errores
				})
				this.$Progress.finish();
			},
			actualizaEstado(){
				let url =  'api/evaluacion_actualiza_estado/' + this.evaluacion.id;
				this.$Progress.start();
				axios.post(url, {
					'protocolo' : this.estadoProtocolo.estado,
					'obligatoria' : this.estadoObligatorio.estado,
					'comentario' : this.comentario,
				}).then(response => {
					this.resultadoFinal = response.data.data
				}).then(response => {
					this.muestraResumenProtocolo = true;
				})
				
				.catch(e => {
					// Capturamos los errores
				})
				this.$Progress.finish();
			},
			nuevaMedicion(){
				this.protocoloListado();
			}

		},
        mounted() {
			this.evaluacionesValidas();
            // console.log('Component mounted.')
			// this.getPreguntas();
        },
		watch: {
            actualiza: {
                handler: function(val, oldVal) {
					if (val.pregunta_texto == 'p2' || val.pregunta_texto == 'p2_1'){
						this.actualizaFiltro();
					}
					this.obligatorio();
                },
                deep: true
            },
        }, 

    }
</script>


<style scoped>
	.preg {
		padding: 8px !important;
	}

	.bg-primary {
		background-color: #1ab394;
		border-color: #1ab394;
		color: #FFFFFF;
	}
</style>

