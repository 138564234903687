<template>
    <div>
        <div class="mb-2" v-if="muestra=='tabla'">
            <div class="row">
                
                <div>Encuestas protocolo: {{ resumens[0] ? resumens[0].protocolo : 0 }}</div>
                <div>Encuestas otros: {{ resumens[0] ? resumens[0].otros : 0 }}</div>
                
            </div>
            <br>
            <div class="row">
                <button @click="getEvaluaciones()" class="btn btn-primary">
                    Actualiza Evaluaciones
                </button>
            </div>
            <table  class="table">
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Fecha</th>
                        <th>Vehiculo/patente</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="evaluacion in evaluaciones" :key="evaluacion.id">
                        <td>{{ evaluacion.id }}</td>
                        <td>{{ evaluacion.created_at | myDate }}</td>
                        <td>
                            <div v-for="detalle in evaluacion.evaluacion_detalles" :key="detalle.id">
                                {{ detalle.texto }}
                            </div>
                        </td>
                        <td>
                            <button @click="muestraEncuesta(evaluacion)" :class="evaluacion.obligatoria && evaluacion.protocolo ? 'btn btn-primary' : 'btn yellow-bg'">
                                Encuesta
                            </button>
                        </td>
                    </tr>
                
                </tbody>
            </table>
        </div>
        <div v-if="muestra=='encuesta'">
            <formulario-encuesta :evaluacion="evaluacion"></formulario-encuesta>
        </div>

    </div>
</template>

<script>

    import FormularioEncuesta from './FormularioEncuesta.vue';

    export default {
        components: {
            FormularioEncuesta
        },
        data() {
            return {
                resumens: {},
                evaluaciones: {},
                evaluacion: {},
                encuesta: {},
                muestra: 'tabla',
            }
        },
        methods: {
			getEvaluaciones() {
                  this.$Progress.start();
                  axios.get('api/evaluacion_protocolo').then(({ data }) => (this.evaluaciones = data.data));
                  this.$Progress.finish();
            },
            getResumenEncuestas() {
                  this.$Progress.start();
                  axios.get('api/resumen_encuesta').then(({ data }) => (this.resumens = data.data));
                  this.$Progress.finish();
            },
            muestraEncuesta(evaluacion){
                this.evaluacion = evaluacion;
                this.muestra = 'encuesta';
            }
		},
        mounted() {
            // console.log('Component mounted.')
			this.getEvaluaciones();
            this.getResumenEncuestas();
        }
    }
</script>

<style lang="scss" scoped>

</style>