export default class Gate{

    constructor(user){
        this.user = user;
    }

    isAdmin(){
        return this.user.type === 'admin';
    }

    isSupervisor(){
        return this.user.type === 'supervisor';
    }
    
    isAdminOrSupervisor(){
        if(this.user.type === 'supervisor' || this.user.type === 'admin'){
            return true;
        }
    }

    isAuditor(){
        return this.user.type === 'auditor';
    }

    isEncuestador(){
        return this.user.type === 'encuestador';
    }

    isTerrenoAuditor(){
        return this.user.type === 'terreno_auditor';
    }

    isTerrenoEncuestador(){
        return this.user.type === 'terreno_encuestador';
    }
}

