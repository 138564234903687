<template>
    <div class='col-sm-12'>
        <div :class="ingresado ? 'bg-primary' : pregunta.obligatoria ? 'obligatoria' : ''">
            <span v-if="muestraP">{{ pregunta.pregunta }}</span>
            {{ pregunta.literal }} {{ tipo_medicion }}
        </div>
        
        <select  v-model="tipo_vehiculo" @change="creaMedicion()" class='form-control' :disabled="!evaluacion ? false : true">
            <option value='' selected disabled>Seleccione número de autos</option>
            <option value='auto'>Auto</option>
            <option value='moto'>Moto</option>
            <option value='camioneta'>Camioneta</option>
            <option value='camion'>Camión</option>
        </select>
    </div>
</template>

<script>
    import moment from 'moment';

    export default {
        props: ['pregunta', 'evaluacion', 'muestraP', 'tipo_medicion', 'estacion_de_servicio_id'],
        
        data() {
            return {
                tipo_vehiculo: null,
                ingresado: false,
            }
        },

        methods: {
            creaMedicion() {

                axios.post('/api/evaluacion', 
                    {
                        'hora_ini_app' : moment().format('H:mm:ss'),
                        'estacion_de_servicio_id': this.estacion_de_servicio_id,
                        'tipo_medicion' : this.tipo_medicion,
                        'tipo_vehiculo' : this.tipo_vehiculo,
                        'pregunta' : this.pregunta,
                        'completo' : true
                    }
                )
                .then(response => {
                    this.ingresado = true;
                    this.$emit('update:evaluacion', response.data.data);

                })
                .catch(error => {
                    this.form.errors = ['Something went wrong. Please try again.'];
                });
            }
        },
    }
</script>

<style lang="scss" scoped>
    .obligatoria{
        background-color: #ff0000;
        color: #FFFFFF;
    }

    .bg-primary {
		background-color: #1ab394;
		border-color: #1ab394;
		color: #FFFFFF;
	}

</style>