import { render, staticRenderFns } from "./ListadoEncuestasProtocolo.vue?vue&type=template&id=526370bb&scoped=true&"
import script from "./ListadoEncuestasProtocolo.vue?vue&type=script&lang=js&"
export * from "./ListadoEncuestasProtocolo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "526370bb",
  null
  
)

export default component.exports