<template>
    <div class="container white-bg">
        <div class="mb-2" >
            <div class="form-group row ibox-content">
                <h2>Resumen diario de Auditorías</h2>
                <hr>
                <label class="col-lg-1 col-form-label">Fecha</label>
                <div class="col-lg-3">
                    <input v-model="fecha" type="date" placeholder="Fecha" class="form-control"> 
                </div>

                <div class="col-lg-2">
                    <button @click="getResultado()" class="btn btn-primary" :disabled="!fecha">Filtrar</button> 
                </div>

            </div>

            <table  class="table table-hover">
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Estación</th>
                        <th>Auditor</th>
                        <th class="text-center">n</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="resumen in resumens" :key="resumen.id">
                        <td>{{ resumen.estacion_de_servicio_id }}</td>
                        <td>{{ resumen.estacion }}</td>
                        <td>{{ resumen.name }}</td>
                        <td class="text-center">{{ resumen.n }}</td>
                    </tr>
                
                </tbody>
            </table>
        </div>


    </div>
</template>

<script>
    export default {
        // resumen_auditorias_diario
        data() {
            return {
                fecha: null,
                resumens: {}
            }
        },

        methods: {
            getResultado() {
                let url =  'api/resumen_auditorias_diario';
				this.$Progress.start();
				axios.post(url, {
					'fecha' : this.fecha,
				}).then(response => {
					this.resumens = response.data.data
				})
				
				.catch(e => {
					// Capturamos los errores
				})
				this.$Progress.finish();
            }
        },
        created () {
            // this.getResultado();
        },
    }
</script>

<style lang="scss" scoped>

</style>