<template>
    <div>
        <tiempos v-if="$gate.isAuditor()"></tiempos>

        <encuesta v-if="$gate.isEncuestador()"></encuesta>

        <resumen-diario v-if="$gate.isTerrenoAuditor()"></resumen-diario>

        <resumen-diario-encuestador v-if="$gate.isTerrenoEncuestador()"></resumen-diario-encuestador>

    </div>
</template>

<script>
    import Tiempos from './Tiempos.vue'
    import Encuesta from './Encuesta.vue'
    import ResumenDiario from './terreno_auditor/ResumenDiario.vue'
    import ResumenDiarioEncuestador from './terreno_encuestador/ResumenDiario.vue'
    
    export default {
        components: {
            Tiempos, Encuesta, ResumenDiario, ResumenDiarioEncuestador
        },
        data() {
            return {
                estacion: {},
            }
        },
        methods: {
			
		},
        mounted() {
            
        }
    }
</script>
