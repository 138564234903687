export default [
    { path: '/dashboard', component: require('./components/Dashboard.vue').default },
    // { path: '/ingreso_full', component: require('./components/IngresoFull.vue').default },
    // { path: '/ingreso_self', component: require('./components/IngresoSelf.vue').default },

    { path: '/profile', component: require('./components/Profile.vue').default },
    { path: '/developer', component: require('./components/Developer.vue').default },
    { path: '/users', component: require('./components/Users.vue').default },
    { path: '/products', component: require('./components/product/Products.vue').default },
    { path: '/product/tag', component: require('./components/product/Tag.vue').default },
    { path: '/product/category', component: require('./components/product/Category.vue').default },
    { path: '*', component: require('./components/NotFound.vue').default }
];
