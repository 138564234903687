<template>
    <div class='col-sm-12'>
        <div :class="ingresado ? 'bg-primary' : pregunta.obligatoria ? 'obligatoria' : ''">
            <span v-if="muestraP">{{ pregunta.pregunta }}</span>
            {{ pregunta.literal }}
        </div>
        <select v-model="valor" @change="creaEvaluacionDetalle()" :disabled="ingresado" class='form-control'>
            <option value='0'>0</option>
            <option value='1'>1</option>
            <option value='2'>2</option>
            <option value='3'>3</option>
            <option value='4'>4</option>
            <option value='5'>5</option>
            <option value='6'>6</option>
            <option value='7'>7</option>
            <option value='8'>8</option>
            <option value='9'>9</option>
            <option value='10'>10</option>
            <option value='11'>11</option>
            <option value='12'>12</option>
            <option value='13'>13</option>
            <option value='14'>14</option>
            <option value='15'>15</option>
            <option value='16'>16</option>
            <option value='17'>17</option>
            <option value='18'>18</option>
            <option value='19'>19</option>
            <option value='20'>20</option>
            <option value='21'>21</option>
            <option value='22'>22</option>
            <option value='23'>23</option>
            <option value='24'>24</option>
            <option value='25'>25</option>
            <option value='26'>26</option>
            <option value='27'>27</option>
            <option value='28'>28</option>
            <option value='29'>29</option>
        </select>
    </div>
</template>

<script>
    import moment from 'moment';

    export default {
        props: ['pregunta', 'evaluacion_id', 'muestraP'],
        data() {
            return {
                ingresado: false,
                valor: null
            }
        },
        methods: {
            creaEvaluacionDetalle() {
                axios.post('/api/evaluacion_detalle/' + this.evaluacion_id, 
                    {
                        'hora_ini_app' : moment().format('H:mm:ss'),
                        'preguntas_paso_servicio_id' : this.pregunta.id,
                        'pregunta_texto' : this.pregunta.pregunta,
                        'valor' : this.valor,
                        'texto' : null,
                        'completo' : true
                    }
                )
                .then(response => {
                    this.ingresado = true;
                    this.$emit('update:actualiza', response.data.data);

                })
                .catch(error => {
                    this.form.errors = ['Something went wrong. Please try again.'];
                });
            }
        },
    }
</script>

<style lang="scss" scoped>
    .obligatoria{
        background-color: #ff0000;
        color: #FFFFFF;
    }

    .bg-primary {
		background-color: #1ab394;
		border-color: #1ab394;
		color: #FFFFFF;
	}
</style>