<template>
    <div class='col-sm-12'>
        <div :class="final ? 'bg-primary' : pregunta.obligatoria ? 'obligatoria' : ''">
            <span v-if="muestraP">{{ pregunta.pregunta }}</span>
            {{ pregunta.literal }}
        </div>
        <button :disabled="ingresado" @click="creaEvaluacionDetalle()" :class="ingresado ? 'btn btn-primary' : 'btn'" type='button'>
            <i class='fa fa-clock-o'></i> Inicio
        </button>
        <button :disabled="final" v-if="ingresado" @click="creaEvaluacionDetalleFin()" :class="final ? 'btn btn-primary' : 'btn'" type='button'>
            <i class='fa fa-clock-o'></i> Fin
        </button>
    </div>
</template>

<script>

    import moment from 'moment';

    export default {
        props: ['pregunta', 'evaluacion_id', 'muestraP'],
        data() {
            return {
                ingresado: false,
                final: false
            }
        },
        methods: {
            creaEvaluacionDetalle() {
                axios.post('/api/evaluacion_detalle/' + this.evaluacion_id, 
                    {
                        'hora_ini_app' : moment().format('H:mm:ss'),
                        'preguntas_paso_servicio_id' : this.pregunta.id,
                        'pregunta_texto' : this.pregunta.pregunta,
                        'valor' : null,
                        'texto' : null,
                        'completo' : false
                    }
                )
                .then(response => {
                    this.ingresado = true;
                    this.$emit('update:actualiza', response.data.data);

                })
                .catch(error => {
                    this.form.errors = ['Something went wrong. Please try again.'];
                });
            },
            creaEvaluacionDetalleFin() {
                axios.post('/api/evaluacion_detalle_fin/' + this.evaluacion_id, 
                    {
                        'hora_fin_app' : moment().format('H:mm:ss'),
                        'preguntas_paso_servicio_id' : this.pregunta.id,
                        'pregunta_texto' : this.pregunta.pregunta,
                        'valor' : null,
                        'texto' : null,
                        'completo' : true
                    }
                )
                .then(response => {
                    this.final = true;
                    this.$emit('update:actualiza', response.data);

                })
                .catch(error => {
                    this.form.errors = ['Something went wrong. Please try again.'];
                });
            }
        },
    }
</script>

<style lang="scss" scoped>
    .obligatoria{
        background-color: #ff0000;
        color: #FFFFFF;
    }

    .bg-primary {
		background-color: #1ab394;
		border-color: #1ab394;
		color: #FFFFFF;
	}
</style>